import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'


const Page = ({data}) => {
  
  return (
  <Layout>
    <SEO title="Privacy policy - INMAGIK" />
    <div className="main-content container p-4 font-100">
      <h4 className="display-medium font-300 text-primary mb-3">Privacy policy</h4>    
      
      <h5>Informativa ai sensi dell’art. 13 del D. Lgs. 196/2003 e dell’articolo 13 del Regolamento UE n. 2016/679</h5>
      <p>
      La presente informativa è resa, nel rispetto degli artt. 13 e 14 del Regolamento UE 679/2016 (di seguito “Regolamento”), agli utilizzatori (di seguito: “Utenti” oppure “Utente”) del sito in versione desktop e mobile inmagik.it e inmagik.com, (di seguito: “Sito”) di proprietà di INMAGIK srl (di seguito: "Società"). Titolare del Trattamento dei dati personali (di seguito: “Titolare”) ed ha lo scopo di descrivere le modalità di gestione del Sito con riferimento al trattamento dei dati personali, nonché di consentire agli Utenti del Sito di conoscere le finalità e le modalità di trattamento dei dati personali da parte del Titolare in caso di loro conferimento.
      </p>

      <h5>
      Titolare del trattamento
      </h5>
      <p>
        INMAGIK srl, via Sant'Orsola, 2 24121 Bergamo (BG) - C.F. e P.IVA 03934600168, numero REA BG - 420856 - MAIL PEC: bianchimro@pec.it
      </p>

      <h5>
        Modalità di Trattamento e Conservazione dei Dati Personali
      </h5>
      <p>
        Il Titolare assicura che i dati personali sono trattati nel pieno rispetto del Regolamento, mediante sistemi manuali, informatici o telematici.
      </p>
      <p>
        I dati personali verranno trattati per mezzo di strumenti automatizzati e non automatizzati per le finalità e per il tempo strettamente necessari a conseguire gli scopi per cui sono stati raccolti. Il trattamento dei dati avverrà per mezzo di strumenti e con modalità volte ad assicurare la riservatezza e la sicurezza dei dati, nel rispetto di quanto definito dal Codice e in conformità alla normativa vigente.
      </p>
      <p>
        Ai sensi dell’art. 7 comma 3 del Regolamento, l’interessato ha diritto di ottenere in qualsiasi momento la revoca del consenso al trattamento. Qualora non pervenga al Titolare una richiesta di cancellazione, i dati personali saranno conservati per un termine non superiore a 10 (dieci) anni, con decorrenza dalla data dell’ultimo accesso a Sito da parte dell’Utente.
      </p>


    </div>
 
 
   
  </Layout>
)}

export default Page
